import React from "react";
import { connect } from "react-redux";
import { Container, Col } from "react-bootstrap";
import uuid from "react-uuid";
import "./TreeInfoCard.css";
import ResponsiveAccordion from "../ResponsiveAccordion/ResponsiveAccordion";
import { treeInfoExists } from "../../utilities/treeInfoCard";
import { convertXArea } from "../../utilities/conversions";
import createGlossaryLink from "../../utilities/glossaryLinker";

class Considerations extends React.Component {
  renderBranchStrength = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.considerations &&
        this.props.treeDetail.considerations.branchStrength &&
        (this.props.treeDetail.considerations.branchStrength
          .branch_strength_low ||
          this.props.treeDetail.considerations.branchStrength
            .branch_strength_high)
      ) {
        const { branch_strength_low, branch_strength_high } =
          this.props.treeDetail.considerations.branchStrength;
        if (branch_strength_low === null && branch_strength_high === null) {
          return;
        }
        return (
          <span className="tree-info-card-text">
            {createGlossaryLink("Branch Strength")}:{" "}
            <span>
              {branch_strength_low === branch_strength_high
                ? `${branch_strength_low}`
                : `${branch_strength_low} to ${branch_strength_high}`}
            </span>
          </span>
        );
      } else {
        throw new Error(`branchStrength missing`);
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.renderBranchStrength: ${err}`
      );
      return null;
    }
  };

  renderRootDamagePotential = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.considerations &&
        this.props.treeDetail.considerations.rootDamagePotential
      ) {
        return (
          <span className="tree-info-card-text">
            {createGlossaryLink("Root Damage Potential")}:{" "}
            <span>
              {this.props.treeDetail.considerations.rootDamagePotential}
            </span>
          </span>
        );
      } else {
        throw new Error(`rootDamagePotential missing`);
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.renderRootDamangePotential: ${err}`
      );
      return null;
    }
  };

  renderHealthHazards = () => {
    try {
      const hi = this.props.treeDetail.considerations;
      if (hi.healthHazards && hi.healthHazards.length) {
        return (
          <span className="tree-info-card-text">
            Potential health issues:{" "}
            {hi.healthHazards.map((hazard, idx) => {
              hazard = hazard === "None Known" ? "No known hazards" : hazard;
              return (
                <span key={uuid()}>
                  {hazard}
                  {idx < hi.healthHazards.length - 1 ? ", " : null}
                </span>
              );
            })}
          </span>
        );
      } else {
        throw new Error(`healthHazards missing`);
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.renderHealthHazards: ${err}`
      );
      return null;
    }
  };

  renderBiogenicEmissions = () => {
    try {
      if (
        this.props.treeDetail &&
        this.props.treeDetail.considerations &&
        this.props.treeDetail.considerations.biogenicEmissions
      ) {
        return (
          <span className="tree-info-card-text">
            {createGlossaryLink("Biogenic Emissions")}:{" "}
            {this.props.treeDetail.considerations.biogenicEmissions}
          </span>
        );
      } else {
        throw new Error(`biogenicEmissions missing`);
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.renderBiogenicEmissions: ${err}`
      );
      return null;
    }
  };

  renderAttractsWildlife = () => {
    try {
      const hi = this.props.treeDetail.considerations;
      if (hi.attractsWildlife && hi.attractsWildlife.length) {
        const wl = hi.attractsWildlife.join(", ");
        return wl.toLowerCase();
      } else {
        throw new Error(`attractsWildlife missing`);
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.renderAttractsWildlife: ${err}`
      );
      return null;
    }
  };

  renderDeerPalatable = () => {
    try {
      const { deerPalatable } = this.props.treeDetail.considerations;
      if (deerPalatable === false) {
        return `Tree is ${createGlossaryLink("Deer Resistant")}`;
      } else {
        throw new Error(`deerPalatable missing`);
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.rendersDeerPalatable: ${err}`
      );
      return null;
    }
  };

  renderWildlifeInteractions = () => {
    try {
      if (this.renderDeerPalatable() || this.renderAttractsWildlife()) {
        let response = `Wildlife interactions:`;
        if (this.renderDeerPalatable() && this.renderAttractsWildlife()) {
          response = `${response} ${this.renderDeerPalatable()} and attracts ${this.renderAttractsWildlife()}`;
        } else if (this.renderDeerPalatable()) {
          response = `${response} ${this.renderDeerPalatable()}`;
        } else if (this.renderAttractsWildlife()) {
          response = `${response} Attracts ${this.renderAttractsWildlife()}`;
        } else {
          response = null;
        }
        return <span className="tree-info-card-text">{response}</span>;
      } else {
        throw new Error("No wildlife interactions");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.rendersDeerPalatable: ${err}`
      );
      return null;
    }
  };

  renderDiseaseResistant = () => {
    try {
      const pd = this.props.treeDetail.considerations;
      if (pd.diseaseResistant && pd.diseaseResistant.length) {
        const dr = pd.diseaseResistant.join(", ");
        return dr;
      } else {
        throw new Error("diseaseResistant length is empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.renderDiseaseResistant: ${err}`
      );
      return null;
    }
  };

  renderDiseaseSusceptibility = () => {
    try {
      const pd = this.props.treeDetail.considerations;
      if (pd.diseaseSusceptibility && pd.diseaseSusceptibility.length) {
        const diseases = pd.diseaseSusceptibility.join(", ");
        return diseases;
      } else {
        throw new Error("diseaseSusceptibility length is empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.renderDiseaseSusceptibility: ${err}`
      );
      return null;
    }
  };

  renderPestResistant = () => {
    try {
      const pd = this.props.treeDetail.considerations;
      if (pd.pestResistant && pd.pestResistant.length) {
        const pr = pd.pestResistant.join(", ");
        return pr;
      } else {
        return null;
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.considerations.renderPestResistant: ${err}`
      );
      return null;
    }
  };

  renderPestSusceptibility = () => {
    try {
      const pd = this.props.treeDetail.considerations;
      if (pd.pestSusceptibility && pd.pestSusceptibility.length) {
        const pests = pd.pestSusceptibility.join(", ");
        return pests;
      } else {
        throw new Error("pestSuceptibility length is empty");
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.renderPestSusceptibility: ${err}`
      );
      return null;
    }
  };

  renderCombSusceptibility = () => {
    try {
      const disease_susceptiblity = this.renderDiseaseSusceptibility();
      const pest_susceptiblity = this.renderPestSusceptibility();
      if (pest_susceptiblity || disease_susceptiblity) {
        let res = "";

        if (disease_susceptiblity) {
          res += disease_susceptiblity;
        }
        if (pest_susceptiblity) {
          res += (res ? " and " : "") + pest_susceptiblity;
        }

        return (
          <span className="tree-info-card-text">
            {createGlossaryLink("Disease")} and {createGlossaryLink("Pest")} susceptibility: {res}
          </span>
        );
      } else {
        throw new Error("No combined susceptibility values");
      }
    } catch (err) {
      console.log(
        `components.TreeInfo.Considerations.renderCombSusceptibility: ${err}`
      );
      return null;
    }
  };

  renderCombResist = () => {
    try {
      if (this.renderDiseaseResistant() || this.renderPestResistant()) {
        let res = null;

        console.log(this.renderPestResistant());

        if (this.renderDiseaseResistant() && this.renderPestResistant()) {
          res = `${this.renderDiseaseResistant()} and ${this.renderPestResistant()}`;
        } else if (this.renderDiseaseResistant()) {
          res = `${this.renderDiseaseResistant()}`;
        } else if (this.renderPestResistant()) {
          res = `${this.renderPestResistant()}`;
        }

        return (
          <span className="tree-info-card-text">
            {createGlossaryLink("Disease Resistance")} and {createGlossaryLink("Pest Resistance")}: {res}
          </span>
        );
      } else {
        throw new Error("No combined resistant values");
      }
    } catch (err) {
      console.log(
        `components.TreeInfo.Considerations.renderCombResist: ${err}`
      );
      return null;
    }
  };

  renderUtilityFriendly = () => {
    try {
      const pd = this.props.treeDetail.considerations;

      const friendly = parseInt(pd.utility_friendly) ? "Yes" : "No";

      return pd.utility_friendly !== null ? (
        <span className="tree-info-card-text">
          {createGlossaryLink("Utility Friendly")}: <span>{friendly}</span>
        </span>
      ) : (
        ""
      );
    } catch (err) {
      console.log(
        `components.TreeInfo.Considerations.renderUtilityFriendly: ${err}`
      );
      return null;
    }
  };

  renderPlantingArea = () => {
    try {
      const { planting_area } = this.props.treeDetail.considerations;
      if (planting_area) {
        return (
          <span className="tree-info-card-text">
            {createGlossaryLink("Recommended Planting Area")}: <span>{planting_area}</span>
          </span>
        );
      } else {
        throw new Error(`planting_area missing`);
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.renderPlantingArea: ${err}`
      );
      return null;
    }
  };

  renderApprasialInfo = () => {
    try {
      const { app_lcant, appraisal, appraisal_text } =
        this.props.treeDetail.considerations;
      if (appraisal == "Yes") {
        return (
          <span className="tree-info-card-text">
            <a href="/appraisals">WCISA Appraisal:</a>{" "}
            <span>
              {app_lcant !== "Use BTF" ? "Suggested LCANT " : ""}
              {`${app_lcant}, ${appraisal_text}`}
            </span>
          </span>
        );
      }
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.renderAppraisalInfo: ${err}`
      );
      return null;
    }
  };

  checkRenderInfo = () => {
    try {
      return treeInfoExists(
        this.renderBranchStrength(),
        this.renderPlantingArea(),
        this.renderRootDamagePotential(),
        this.renderHealthHazards(),
        this.renderBiogenicEmissions(),
        this.renderWildlifeInteractions(),
        this.renderCombSusceptibility(),
        this.renderCombResist(),
        this.renderUtilityFriendly(),
        this.renderApprasialInfo()
      );
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.checkRenderInfo: ${err}`
      );
      return false;
    }
  };

  renderContainer = () => {
    try {
      return (
        <Container className="tree-info-card--considerations">
          {this.renderBranchStrength()}
          {/*this.renderPlantingArea()*/}
          {this.renderRootDamagePotential()}
          {this.renderHealthHazards()}
          {this.renderBiogenicEmissions()}
          {this.renderWildlifeInteractions()}
          {this.renderCombSusceptibility()}
          {this.renderCombResist()}
          {this.renderPestResistant()}
          {this.renderUtilityFriendly()}
          {this.renderApprasialInfo()}
        </Container>
      );
    } catch (err) {
      console.log(
        `components.TreeInfoCard.Considerations.renderContainer: ${err}`
      );
    }
  };

  render() {
    return this.checkRenderInfo() ? (
      <Col lg={6} xs={12} className="mb-4">
        <ResponsiveAccordion title="CONSIDERATIONS">
          {this.renderContainer()}
        </ResponsiveAccordion>
      </Col>
    ) : null;
  }
}

const mapStateToProps = state => {
  return { treeDetail: state.treeDetail.data };
};
export default connect(mapStateToProps, {})(Considerations);