import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Route, Switch } from "react-router-dom";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

// COMPONENTS
import SelecTree from "../../components/SelecTree/SelectTree";
import Result from "../../components/Result/Result";

import DownloadPage from "../../components/Download/DownloadPage";
import UploadPage from "../../components/Upload/UploadPage";
import TreeDetail from "../../components/TreeDetail/TreeDetail";
import EditTree from "../../components/TreeForm/EditTree";
import Appraisals from "../../components/Appraisals/Appraisals";
import TreeCharacteristicsGlossary from "../../components/TreeCharacteristicsGlossary/TreeCharacteristicsGlossary";
import PacifcIslandsFooter from "../../components/PacificIslandsFooter/PacificIslandsFooter";
import SelectreeHeader from "../../components/SelectreeHeader/SelectreeHeader";

import UserAccountEdit from "../UserAccountEdit/UserAccountEdit";
import UserAccountInvite from "../UserAccountInvite/UserAccountInvite";
import UserAccounts from "../UserAccounts/UserAccounts";
import ResetPassword from "../ResetPassword/ResetPassword";
import VerifyAccount from "../VerifyAccount/VerifyAccount";

// error components
import NotFound from "../../components/NotFound/NotFound";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
// import sessionStorage for building sessionStorage objects
import {
  setSearchPageOptions,
  getSearchOptions,
} from "../../utilities/sessionStorage";
import { store } from "./store";

// global css styles
import "./App.css";

import MyListPage from "../MyListPage/MyListPage";
import ListSearchPage from "../ListSearchPage/ListSearchPage";
import ListDetail from "../ListDetail/ListDetail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AccountRoute, AdminRoute } from "./AccountRoutes";
import UserChecker from "./UserChecker";

const App = () => {
  // set sessionStorage objects
  useEffect(() => {
    if (!getSearchOptions()) {
      setSearchPageOptions();
    }
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Container className="app-container" fluid>
          <UserChecker />
          <SelectreeHeader />
          <ToastContainer
            autoClose={4000}
            position="bottom-center"
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
          />
          <Switch>
            <Route exact path="/" component={SelecTree} />
            <Route exact path="/pacificislands" component={SelecTree} />
            <Route
              exact
              path="/selectree/:searchFilter"
              component={SelecTree}
            />
            <Route exact path="/search-results" component={Result} />
            <Route exact path="/search-lists" component={ListSearchPage} />
            <Route exact path="/list/:list_id" component={ListDetail} />
            <Route exact path="/appraisals" component={Appraisals} />
            <Route
              exact
              path="/tree-characteristics-glossary"
              render={props => (
                <TreeCharacteristicsGlossary
                  {...props}
                  adminView={
                    new URLSearchParams(props.location.search).get(
                      "adminView"
                    ) === "true"
                  }
                />
              )}
            />
            <Route exact path="/tree-detail/:treeName" component={TreeDetail} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/verify-account" component={VerifyAccount} />

            <AccountRoute path="/account" component={UserAccountEdit} />
            <AccountRoute path="/my-list-page" component={MyListPage} />
            <AdminRoute
              roles={[2, 3]}
              path="/tree-detail/:treeName/edit"
              component={EditTree}
            />
            <AdminRoute
              roles={[2, 3]}
              path="/download"
              component={DownloadPage}
            />
            <AdminRoute
              roles={[3]}
              path="/account/invite"
              component={UserAccountInvite}
            />
            <AdminRoute
              roles={[3]}
              path="/account/users"
              component={UserAccounts}
            />
            <AdminRoute roles={[3]} path="/upload" component={UploadPage} />

            <Route exact path="/error" component={ErrorPage} />
            <Route component={NotFound} />
          </Switch>
          <PacifcIslandsFooter />
        </Container>
      </BrowserRouter>
    </Provider>
  );
};

//            <Route exact path="/upload" component={UploadPage} />
export default App;
