import React from 'react';

const glossaryMap = {
  "Bark Color": "Bark_Color",
  "Bark Texture": "Bark_Texture",
  "Biogenic Emissions": "Biogenic_Emissions",
  "Branch Strength": "Branch_Strength",
  "CA Native": "CA_Native",
  "Carpel": "Carpel",
  "Climate adapted": "Climate_adapted",
  "Deer Resistant": "Deer_Resistant",
  "Disease Resistance": "Disease_Resistance",
  "Disease Susceptibility": "Disease_Susceptibility",
  "Fall Color": "Fall_Color",
  "Flower Color": "Flower_Color",
  "Flower Showiness": "Flower_Showiness",
  "Flower Time": "Flower_Time",
  "Flower Type": "Flower_Type",
  "Foliage Type": "Foliage_Type",
  "Fragrance": "Fragrance",
  "Fruit Color": "Fruit_Color",
  "Fruit Size": "Fruit_Size",
  "Fruit Type": "Fruit_Type",
  "Fruit Value": "Fruit_Value",
  "Fruiting Time": "Fruiting_Time",
  "Growth Rate": "Growth_Rate",
  "Landscape Application": "Landscape_Application",
  "Leaf Arrangement": "Leaf_Arrangement",
  "Leaf Form": "Leaf_Form",
  "Leaf Shape": "Leaf_Shape",
  "Litter Type": "Litter_Type",
  "New Growth Color": "New_Growth_Color",
  "Pest Resistance": "Pest_Resistance",
  "Pest Susceptibility": "Pest_Susceptibility",
  "Recommended Planting Area": "Recommended_Planting_Area",
  "Root Damage Potential": "Root_Damage_Potential",
  "Salt Tolerance": "Salt_Tolerance",
  "Schoolyard Trees": "Schoolyard_Trees",
  "Soil pH": "Soil_pH",
  "Soil Texture": "Soil_Texture",
  "Sunset Climate Zone": "Sunset_Climate_Zone",
  "Taxonomy": "Taxonomy",
  "Maximum Tree Height": "Tree_Height",
  "Tree Height": "Tree_Height",
  "Tree Shape": "Tree_Shape",
  "USDA Hardiness Zone": "USDA_Hardiness_Zone",
  "Utility Friendly": "Utility_Friendly",
  "Water Use Rating": "Water_Use_Rating",
  "Wind Resistance": "Wind_Resistance",
  "Disease": "Disease_Susceptibility",
  "Pest": "Pest_Susceptibility"
};

const createGlossaryLink = (term) => {
  if (!term) return term;
  
  const normalizedTerm = term.trim();
  const glossaryKey = glossaryMap[normalizedTerm] || 
                      glossaryMap[normalizedTerm.toLowerCase()] || 
                      glossaryMap[normalizedTerm.charAt(0).toUpperCase() + normalizedTerm.slice(1).toLowerCase()];
  
  if (glossaryKey) {
    return (
      <a 
        href={`/tree-characteristics-glossary#${glossaryKey}`}
        className="glossary-link"
      >
        {term}
      </a>
    );
  }
  
  return term;
};

export default createGlossaryLink;