export const LIST_MODALS_TYPES = Object.freeze({
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
});

const LIST_MODAL_DEFAULT_STATE = {
  type: null,
  data: {},
};

// dispatch({type: LIST_MODALS_TYPES.UPDATE, data: list })

export default (state = LIST_MODAL_DEFAULT_STATE, action) => {
  switch (action.type) {
    case "SET_LIST_MODAL":
      return action.payload;
    case "RESET_LIST_MODAL":
      return LIST_MODAL_DEFAULT_STATE;
    default:
      return state;
  }
};
