import React from "react";
import "./SearchByName.css";

import { Transition } from "react-transition-group";
import SearchByNameSuggestionCard from "./SearchByNameSuggestionCard";
import uuid from "react-uuid";

function SearchByNameSuggestionBox(props) {
  const animationDuration = 200;
  const animationDefaultStyle = {
    transition: `opacity ${animationDuration}ms ease-in-out, max-height ${animationDuration}ms ease-in-out`,
    opacity: 0,
    maxHeight: "0vh",
  };
  const animationTransitionStyles = {
    entering: { opacity: 1, maxHeight: "50vh" },
    entered: { opacity: 1, maxHeight: "50vh" },
    exiting: { opacity: 0, maxHeight: "0vh" },
    exited: { opacity: 0, maxHeight: "0vh" },
  };

  return (
    <Transition in={props.showSuggestions} timeout={animationDuration}>
      {state => (
        <div
          style={{
            ...animationDefaultStyle,
            ...animationTransitionStyles[state],
          }}
          className="search-by-name-suggestion-box"
          onMouseLeave={() => {
            props.setSelectedTree(false);
          }}
        >
          {props.suggestions
            ? props.suggestions.map((suggestion, i) => {
                return (
                  <SearchByNameSuggestionCard
                    key={uuid()}
                    suggestion={suggestion}
                    i={i}
                    submitForm={props.submitForm}
                    setSelectedTree={props.setSelectedTree}
                    selectedTree={props.selectedTree}
                  />
                );
              })
            : ""}
        </div>
      )}
    </Transition>
  );
}

export default SearchByNameSuggestionBox;
