import React, { useEffect, useState } from "react";
import { updateUserListsThunk } from "../../actions/list";
import api from "../../apis/selectree_api";
import { getAuthHeader } from "../../utilities/header";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ListForm from "../TreeDetailListWidget/ListForm";
import "react-toastify/dist/ReactToastify.css";

function optionize(arr = []) {
  return arr.map(label => ({ label, value: label }));
}

export default function UpdateListModal({
  title,
  list,
  children,
  onUpdate,
  open,
}) {
  const [modal, updateModal] = useState(false);
  const dispatch = useDispatch();
  let form_data = { ...list };
  form_data.keywords = optionize(form_data.keywords);
  form_data.categories = optionize(form_data.categories);
  form_data.regions = optionize(form_data.regions);
  useEffect(() => {
    if (open) updateModal(open);
  }, []);

  return (
    <span>
      <span
        style={{ width: "fit-content", blockSize: "fit-content" }}
        onClick={e => {
          e.stopPropagation();
          updateModal(true);
        }}
      >
        {children}
      </span>
      <Modal
        show={modal}
        centered
        onClick={e => e.stopPropagation()}
        onHide={() => updateModal(false)}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListForm
            savedSearch={list.query_data}
            defaultValue={form_data}
            actionLabel="Update List"
            formAction={async formData => {
              const response = await api.patch(
                `/list/update/${list.list_id}`,
                formData,
                getAuthHeader()
              );

              if (response.status < 300) {
                dispatch(updateUserListsThunk(list.list_id));
              }

              if (onUpdate) onUpdate(response);
              updateModal(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </span>
  );
}
