import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Papa from "papaparse";
import { uploadFile } from "../../actions/uploadFile";
import Popup from "./Popup";
import HeaderField from "../FormField/HeaderField";
import { optionsReturn } from "../../utilities/searchChar";
import "./Upload.css";
import { toast } from "react-toastify";

const allowedExtensions = ["csv"];

class UploadPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      col: [],
      rows: [],
      currentRow: "",
      currentCol: "",
      error: "",
      file: "",
      fileUploaded: false,
      i: "",
      j: "",
      table: "",
      headers: [],
      isOpen: false,
      tree_Fields: [
        "tree_id",
        "scientific_name",
        "family",
        "family_previous",
        "soil_ph_low",
        "soil_ph_high",
        "exposure_low",
        "exposure_high",
        "native_range",
        "growth_rate_low",
        "growth_rate_high",
        "foliage_type",
        "foliage_fall_color",
        "fruiting_habit",
        "fruit_size",
        "fruit_color",
        "fruit_type",
        "flower_showiness",
        "bark_color",
        "branch_strength_low",
        "branch_strength_high",
        "armament",
        "fragrance",
        "biogenic_emissions",
        "deer_palatable",
        "salt_tolerance",
        "wind_resistance",
        "root_damage_potential",
        "memo",
        "photolocations",
        "low_zone",
        "medium_zone",
        "pacific_island",
        "lastedited",
        "ca_invasive",
        "invasive_text",
        "height_low",
        "height_high",
        "height_memo",
        "width_low",
        "width_high",
        "tropical",
        "sex",
        "flower_type",
        "display",
        "redirect",
        "california_native",
        "planting_area",
        "pi_invasive",
        "water_use",
        "leaf_arrangement",
        "leaf_form",
        "leaflet_shape",
        "general_native_range",
        "hi_native",
        "ethnobotanical_info",
        "schoolyard",
        "schoolyard_use_notes",
        "shade_tolerant",
        "utility_friendly",
      ],
      climate_zones_Fields: [
        "climate_zones_id",
        "climate_zone",
        "facets_id",
        "facets_text",
        "facets_tree_match_id",
        "facets_id",
        "climate_zones_id",
        "match_value",
        "zip_code_climate_zones_id",
        "zip_code",
        "match_value",
        "inland_empire_adapted",
        "inland_valleys_adapted",
        "interior_west_adapted",
        "northern_california_coast_adapted",
        "southern_california_coast_adapted",
        "southern_desert_adapted",
      ],
    };
  }

  async componentDidMount() {
    var headers = [
      "tree_id",
      "synonyms",
      "displayed_scientific_name",
      "flower_type",
      "family",
      "display",
      "pacific_island",
      "displayed_common_name",
      "alternative_common_names",
      "generic_common_names",
      "memo",
      "photolocations",
      "landscape_application",
      "landscape_use",
      "native_range",
      "california_native",
      "ca_invasive",
      "hi_native",
      "invasive_text",
      "height_low",
      "height_high",
      "width_low",
      "width_high",
      "growth_rate_low",
      "growth_rate_high",
      "foliage_type",
      "foliage_type_low",
      "foliage_type_high",
      "tree_shape",
      "litter_type",
      "bark_color",
      "bark_texture",
      "branch_strength_low",
      "branch_strength_high",
      "leaf_form",
      "leaf_arrangement",
      "leaflet_shape",
      "foliage_growth_color",
      "foliage_fall_color",
      "fruit_size",
      "fruit_color",
      "fruit_type",
      "fruit_value",
      "fruiting_time",
      "flower_showiness",
      "fragrance",
      "flower_color",
      "flower_time",
      "usda_zone",
      "sunset_zone",
      "planting_area",
      "soil_texture",
      "salt_tolerance",
      "wind_resistance",
      "soil_ph_low",
      "soil_ph_high",
      "pest_resistant",
      "pest_susceptibility",
      "disease_resistant",
      "disease_susceptibility",
      "root_damage_potential",
      "health_hazard",
      "attracts_wildlife",
      "biogenic_emissions",
      "deer_palatable",
      "water_use",
      "ethnobotanical_info",
      "climate_zone",
      "facets_id",
      "facets_text",
      "facets_tree_match_id",
      "climate_zones_id",
      "match_value",
      "zip_code_climate_zones_id",
      "zip_code",
      "match_value",
      "inland_empire_adapted",
      "inland_valleys_adapted",
      "interior_west_adapted",
      "northern_california_coast_adapted",
      "southern_california_coast_adapted",
      "southern_desert_adapted",
      "schoolyard",
      "schoolyard_use_notes",
      "appraisal",
      "app_lcant",
      "app_grrating",
      "shade_tolerant",
      "utility_friendly",
    ];
    this.setState({ headers: headers });
  }
  // This function will be called when
  // the file input changes
  handleFileChange = e => {
    this.setState({ error: "" });
    this.setState({ file: "" });

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        this.setState({ error: "Please input a csv file" });
        return;
      }

      // If input type is correct set the state
      this.setState({ file: inputFile });
      this.handleParse(inputFile);
    }
  };
  handleParse = file => {
    var validInput = true;
    // If user clicks the parse button without a file we show a error
    if (!file) return this.setState({ error: "Enter a valid file" });

    // Initialize a reader which allows user to read a file
    const reader = new FileReader();

    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const cleaned_text = target.result.replaceAll("\r", "");
      const csv = Papa.parse(cleaned_text, { header: true });
      const parsedData = csv?.data.sort(
        (a, b) => parseInt(a.tree_id) > parseInt(b.tree_id)
      );
      var columns = Object.keys(parsedData[0]);
      const row_dicts = Object.values(parsedData);
      for (let i = 0; i < columns.length; i++) {
        // filter data, remove HTML tags
        columns[i] = columns[i].toLowerCase().replace(/<[^>]+>/g, "");
        if (
          this.state.tree_Fields.includes(columns[i]) &&
          this.state.climate_zones_Fields.includes(columns[i]) &&
          validInput
        ) {
          alert(
            "Please upload tree characteristics and climate zone information separately!"
          );
          this.setState({ file: "" });
          this.setState({ fileUploaded: false });
          validInput = false;
        }
      }

      if (validInput) {
        this.setState({ col: columns });
        const rows = [];
        rows.push(Object.keys(parsedData[0]));
        for (let i = 0; i < row_dicts.length; i++) {
          rows.push(Object.values(row_dicts[i]));
        }
        rows.splice(0, 1);
        this.setState({ rows: rows });
      }
    };
    if (validInput) {
      this.setState({ fileUploaded: true });
      reader.readAsText(file);
    }
  };

  handleClick = e => {
    e.preventDefault();

    this.setState({ isOpen: !this.state.isOpen });

    let i = e.target.id.split(",")[0];
    let j = e.target.id.split(",")[1];

    this.setState({ i: i, j: j, attr: e.target.innerText });
  };

  togglePopup = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleSubmission = async () => {
    try {
      const { rows, col } = this.state;
      // modify array elements and upload
      const array2D = [...rows];
      array2D.unshift(col);
      const id = toast.loading("Upload In Progress");
      let response = await uploadFile(array2D);
      console.log(response);

      if (response) {
        toast.update(id, {
          render: "Upload Sucessfully Processed!",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      } else {
        toast.update(id, {
          render: "Upload Failed or Took too long!",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      }
      console.log("Response in main page", response);
    } catch (error) {
      console.error("Data Upload failed", error);
      alert("Data Upload failed");
    }
  };
  updateAttr = async e => {
    e.preventDefault();

    let attr = e.target[0].value;

    var newArr = this.state.rows;
    if (this.state.i == -1) {
      var newArr = this.state.col;
      this.state.col[this.state.j] = attr;
      this.setState({ attr: attr, col: newArr });

      return this.state.col;
    }
    newArr[this.state.i][this.state.j] = attr;

    this.setState({ attr: attr, rows: newArr });

    return this.state.rows;
  };

  createID(i, j) {
    var new_id = i + "," + j;
    return new_id;
  }

  optionsHeader = () => {
    try {
      return optionsReturn(this.state.headers);
    } catch (err) {
      console.log(`components.Upload.Headers.optionsHeader: ${err}`);
    }
  };

  render() {
    console.log("this.state", this.state);
    return (
      <div class="upload">
        <h1>Upload Tree Data</h1>
        <hr className="hr-bold-short-black mt-0 mb-3" />
        <form>
          <input
            class=""
            style={{ margin: this.state.file ? "0 0 1rem 0" : "4rem 0 5rem 0" }}
            onChange={this.handleFileChange}
            id="csvInput"
            name="file"
            type="File"
            accept=".csv"
          />
          {/*this.state.file && this.state.file.name? this.state.file.name: "No file selected"*/}
        </form>
        {!this.state.fileUploaded && false && this.state.file && (
          <Button style={{ margin: "0 1rem" }} onClick={this.handleParse}>
            Upload File for Review
          </Button>
        )}

        {this.state.fileUploaded && (
          <div className="results" id="2dtable">
            <table style={{ padding: "0.5rem" }}>
              <thead>
                {this.state.col.map((cell, j) => {
                  return (
                    <th>
                      <HeaderField
                        id={this.createID(-1, j)}
                        field={this.state.col[j]}
                        options={this.optionsHeader()}
                        parentComponent={this}
                      />
                    </th>
                  );
                })}
              </thead>

              <tbody>
                {this.state.rows.map((row, i) => {
                  if (row.length >= this.state.col.length) {
                    return (
                      <tr
                        style={{
                          backgroundColor: i % 2 === 0 ? "#F5F5F5" : "#FFF",
                        }}
                      >
                        {row.map((cell, j) => {
                          var abc = cell;

                          if (abc.length != 0) {
                            return (
                              <td>
                                <button
                                  className="upload-preview-btn"
                                  id={this.createID(i, j)}
                                  onClick={this.handleClick}
                                >
                                  {cell}
                                </button>
                              </td>
                            );
                          }

                          return (
                            <td>
                              <button
                                className="upload-preview-btn"
                                id={this.createID(i, j)}
                                onClick={this.handleClick}
                              >
                                ___
                              </button>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  } else {
                    return "";
                  }
                })}

                {this.state.isOpen && (
                  <Popup
                    content={
                      <>
                        <form
                          className="alterAttr"
                          method="post"
                          onSubmit={this.updateAttr}
                        >
                          <input
                            type="text"
                            name="alterAttr"
                            placeholder={this.state.currentAttr}
                          ></input>
                          <input type="submit" value="Submit"></input>
                        </form>
                      </>
                    }
                    handleClose={this.togglePopup}
                  />
                )}
              </tbody>
            </table>
            {this.state.isOpen && (
              <Popup
                content={
                  <>
                    <form
                      className="alterAttr"
                      method="post"
                      onSubmit={this.updateAttr}
                    >
                      <input
                        type="text"
                        name="alterAttr"
                        placeholder={this.currentAttr}
                      ></input>
                      <input type="submit" value="Submit"></input>
                    </form>
                  </>
                }
                handleClose={this.togglePopup}
              />
            )}
          </div>
        )}

        {this.state.fileUploaded && (
          <Button style={{ margin: "1rem" }} onClick={this.handleSubmission}>
            Submit Changes to Database
          </Button>
        )}
      </div>
    );
  }
}

export default UploadPage;
