import React from "react";
import { useSelector } from "react-redux";
import { LIST_MODALS_TYPES } from "../../reducers/list_modal";
import UpdateListModal from "./UpdateListModal";
import DeleteModal from "./DeleteModal";
import CreateListModal from "./CreateListModal";

export default function ListModalController() {
  const list_modal = useSelector(state => state.list_modal);

  if (!list_modal || !list_modal.type) {
    return <></>;
  }

  switch (list_modal.type) {
    case LIST_MODALS_TYPES.CREATE:
      return <CreateListModal {...list_modal} open={true} />;

    case LIST_MODALS_TYPES.UPDATE:
      return <UpdateListModal {...list_modal} open={true} />;

    case LIST_MODALS_TYPES.DELETE:
      return <DeleteModal {...list_modal} open={true} />;
  }
}
