import { useState, useEffect } from "react";

function useSyncFetch(val, ms_delay, fetch, dependency = []) {
  const [syncData, setSyncData] = useState({ sync: 0, value: val });
  const [sync, setSync] = useState(0);

  function calcSync() {
    try {
      if (!fetch || fetch === undefined) return;

      setSync(sync => sync + 1);
      fetch().then(res => {
        if (res === undefined || res.data === undefined) return;

        setSyncData(data => {
          if (sync > data.sync)
            return { sync: sync, value: res.data.slice(0, 100) };
          return data;
        });
      });
    } catch (err) {
      console.log(`SyncFetch error: ${err}`);
    }
  }

  useEffect(() => {
    const timeOut = setTimeout(() => calcSync(), ms_delay);
    return () => clearTimeout(timeOut);
  }, dependency);

  return [syncData.value];
}

export default useSyncFetch = useSyncFetch;
