import React, { useEffect, useState } from "react";
import "./ListSearchPage.css";
import qs from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { categories } from "../TreeDetailListWidget/ListWidgetOptions";
import ListItem from "../ListItems/ListItem";
import { Button, Col, Container, Row } from "react-bootstrap";
import ResultPagination from "../ResultPagination/ResultPagination";
import {
  LIST_FOLLOWER,
  PAGES_TO_DISPLAY,
  PAGE_TOP_DESKTOP,
} from "../../utilities/constants";
import { NUMBER_OF_RESULTS } from "../../utilities/constants";

import { getSearchListThunk } from "../../actions/search";
import ErrorPage from "../ErrorPage/ErrorPage";
import MultiTextInput from "../Selectors/MultiTextInput";

import { getUserListsThunk } from "../../actions/list";
import { Link } from "react-router-dom";

import ActionDropdown, {
  BanList,
  BanUser,
  CopyAction,
  DownloadAction,
  DuplicateListAction,
  FollowAction,
} from "../ActionDropdown/ActionDropdown";
import ListItemSkeleton from "../LoadingSkeletons/ListItemSkeleton";
import ListModalController from "../ListModals/ListModalController";

function ListSearchPage() {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const lists = useSelector(state => state.listSearchResults);
  const user_lists = useSelector(state => state.user_lists);
  const user = useSelector(state => state.user);
  const searchOptions = useSelector(state => state.listSearchOptions);

  const RESULTS_PER_PAGE = NUMBER_OF_RESULTS[0];

  useEffect(() => {
    async function getLists() {
      try {
        const hashOptions = qs.parse(window.location.hash);

        if (hashOptions.activePage) {
          hashOptions.activePage = parseInt(hashOptions.activePage);
        }
        if (hashOptions.resultsPerPage) {
          hashOptions.resultsPerPage = parseInt(hashOptions.resultsPerPage);
        }

        const searchLists =
          hashOptions.keys > 0
            ? getSearchListThunk(hashOptions)
            : getSearchListThunk(searchOptions);
        dispatch(searchLists).then(() => {
          setLoading(false);
        });
        dispatch(getUserListsThunk());
      } catch {
        setError(true);
      }
    }
    getLists();
  }, []);

  function ActionItem(list) {
    const followed = user_lists.find(ul => ul.list_id === list.list_id);

    return (
      <div style={{ alignSelf: "center" }}>
        <ActionDropdown>
          {user && (!followed || followed.role === LIST_FOLLOWER) ? (
            <FollowAction list_id={list.list_id} followed={followed} />
          ) : (
            ""
          )}
          <CopyAction
            copy_text={`${window.location.origin}/list/${list.list_id}`}
          />
          {/*<DownloadAction object_array={{}} filename={list.name}/>*/}
          {user ? (
            <>
              <DuplicateListAction list_id={list.list_id} />
            </>
          ) : (
            ""
          )}
          {user && user.role_id === 3 && user.id !== list.user_id ? (
            <>
              <BanList list={list} />
              <BanUser list={list} />
            </>
          ) : (
            ""
          )}
        </ActionDropdown>
      </div>
    );
  }

  function ListGallery() {
    const page_start = (searchOptions.activePage - 1) * RESULTS_PER_PAGE;
    return lists
      .slice(page_start, page_start + RESULTS_PER_PAGE)
      .map((list, index) => {
        return (
          <ListItem
            key={index}
            eventKey={index}
            src={
              list.thumbnail
                ? `${process.env.REACT_APP_BASE_IMAGE_URL}${list.thumbnail}`
                : "/images/default-tree.png"
            }
            updateTime={
              list.date_updated ? list.date_updated : list.date_created
            }
            tags={[
              list.type,
              list.isPublic ? "Public" : "Private",
              list.regions ? list.regions.join(", ") : "",
              list.keywords ? list.keywords.join(", ") : "",
            ]}
            heading={<b>{list.name.toUpperCase()}</b>}
            subHeading={`${list.first_name} ${list.last_name}`}
            description={list.description}
            type={list.type}
            list={list}
            href={`/list/${list.list_id}`}
            actionItem={ActionItem(list)}
          />
        );
      });
  }

  const createOption = label => ({ label, value: label });

  function ListSearchOps() {
    const california = [
      { value: "All of California", label: "All of California" },
      { value: "Inland Empire", label: "Inland Empire" },
      { value: "Inland Valleys", label: "Inland Valleys" },
      { value: "Interior West", label: "Interior West" },
      { value: "Northern Coast", label: "Northern Coast" },
      { value: "Southern Coast", label: "Southern Coast" },
      { value: "Southwest Deserts", label: "Southwest Deserts" },
    ];

    const pacific_islands = [
      { value: "Pacific Islands", label: "Pacific Islands" },
      { value: "State of Hawai'i", label: "State of Hawai'i" },
      { value: "Big Island", label: "Big Island" },
      { value: "Maui", label: "Maui" },
      { value: "Moloka’i", label: "Moloka’i" },
      { value: "Oahu", label: "Oahu" },
      { value: "Kauai", label: "Kauai" },
      { value: "Ni’ihau", label: "Ni’ihau" },
      { value: "Guam", label: "Guam" },
      { value: "Samoa", label: "Samoa" },
      { value: "Micronesia", label: "Micronesia" },
      { value: "Marshall Islands", label: "Marshall Islands" },
      { value: "Palau", label: "Palau" },
      { value: "Mariana Islands", label: "Mariana Islands" },
    ];

    const groupStyles = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    const formatRegionLabel = data => (
      <div style={groupStyles}>
        <span>{data.label}</span>
      </div>
    );
    return (
      <Row style={{ marginTop: "0.7rem" }}>
        <Col>
          <MultiTextInput
            defaultValue={
              searchOptions.listSearchTerms
                ? searchOptions.listSearchTerms.map(createOption)
                : []
            }
            options={[
              { label: "Types of Lists", options: categories },
              { label: "California Regions", options: california },
              { label: "Pacific Islands Regions", options: pacific_islands },
              //...regions
            ]}
            formatGroupLabel={formatRegionLabel}
            onEnter={values => {
              const searchTerms = values.map(v => v.value);
              dispatch(
                getSearchListThunk({
                  ...searchOptions,
                  listSearchTerms: searchTerms,
                })
              );
            }}
          />
        </Col>
      </Row>
    );
  }

  function EmptySearch() {
    return (
      <div className="result-gallery-no-results">
        <h2>No Results Found</h2>
        <p>
          The search may be too restrictive. Try searching again with fewer
          options (below)- that will widen the search results.
        </p>
        <h6>
          <Link to="/" onClick={() => window.scrollTo(PAGE_TOP_DESKTOP)}>
            Return to the SelecTree Home
          </Link>
        </h6>
      </div>
    );
  }

  function LoadingSkeleton() {
    let listItems = [];
    for (let i = 0; i < 10; i++) {
      listItems.push(<ListItemSkeleton />);
    }

    return listItems;
  }

  return !error ? (
    <Container>
      <h2 className="search-by-char-header text-center category-label p-3 m-0">
        SEARCH TREE LISTS
      </h2>
      <hr className="hr-bold-short-black mt-0 mb-3" />
      <ListSearchOps />
      <ListModalController />
      <br />
      {!loading ? (
        lists.length > 0 ? (
          <div className="mb-2">
            <ResultPagination
              defaultPage={searchOptions.activePage}
              visiblePages={PAGES_TO_DISPLAY}
              numberOfResults={lists.length}
              resultsPerPage={RESULTS_PER_PAGE}
              onPageChange={page => {
                dispatch({
                  type: "LIST_SEARCH_OPTIONS",
                  payload: { ...searchOptions, activePage: page },
                });
              }}
            >
              <div className="mb-2">
                <ListGallery />
              </div>
            </ResultPagination>
          </div>
        ) : (
          <EmptySearch />
        )
      ) : (
        <LoadingSkeleton />
      )}
    </Container>
  ) : (
    <ErrorPage />
  );
}
export default ListSearchPage;
